<template>
  <div class="note-books">
    <div>
      <b-row>
        <b-col md="5">
          <b-card header-tag="header">
            <template #header>
              <div class="d-flex">
                <div class="mr-auto d-flex d-inline">
                  <i class="fa-solid fa-store text-primary mr-1"></i>
                  <h5 class="mb-0 text-primary justify-content-end">
                    {{ mainDataLeft.storeName.value }}
                  </h5>
                </div>
                <div class="d-flex d-inline">
                  <h5 class="mb-0 text-danger">
                    Ngày: {{ mainDataLeft.createdAt.value }}
                  </h5>
                  <i class="fa-solid fa-calendar-days text-danger ml-1"></i>
                </div>
              </div>
            </template>
            <b-card-text>
              <div class="row m-0">
                <div
                  class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"
                >
                  <span
                    class="svg-icon svg-icon-3x svg-icon-warning d-block my-2"
                  >
                    <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
                  </span>
                  <a
                    href="#"
                    class="text-warning font-weight-bold font-size-h6"
                  >
                    <span class="d-block">
                      {{ mainDataLeft.totalAmountOneDate.name }}</span
                    >
                    <span class="d-block">{{
                      mainDataLeft.totalAmountOneDate.value()
                    }}</span>
                  </a>
                </div>
                <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-primary d-block my-2"
                  >
                    <inline-svg src="media/svg/icons/Design/Layers.svg" />
                  </span>
                  <a
                    href="#"
                    class="text-primary font-weight-bold font-size-h6 mt-2"
                  >
                    <span class="d-block">{{
                      mainDataLeft.totalRow.name
                    }}</span>
                    <span class="d-block">{{
                      mainDataLeft.totalRow.value
                    }}</span>
                  </a>
                </div>
              </div>
              <div class="row m-0">
                <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-danger d-block my-2"
                  >
                    <inline-svg src="media/svg/icons/Cooking/Bowl.svg" />
                  </span>
                  <a
                    href="#"
                    class="text-danger font-weight-bold font-size-h6 mt-2"
                  >
                    <span class="d-block">{{
                      mainDataLeft.noSentRevenue.name
                    }}</span>
                    <span class="d-block">{{
                      mainDataLeft.noSentRevenue.value()
                    }}</span>
                  </a>
                </div>
                <div class="col bg-light-success px-6 py-8 rounded-xl">
                  <span
                    class="svg-icon svg-icon-3x svg-icon-success d-block my-2"
                  >
                    <inline-svg
                      src="media/svg/icons/Communication/Urgent-mail.svg"
                    />
                  </span>
                  <a
                    href="#"
                    class="text-success font-weight-bold font-size-h6 mt-2"
                  >
                    <span class="d-block">{{
                      mainDataLeft.verifyRevenue.name
                    }}</span>
                    <span class="d-block">{{
                      mainDataLeft.verifyRevenue.value()
                    }}</span>
                  </a>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card header-tag="header" class="mt-2">
            <template #header>
              <div class="d-flex d-inline m-2">
                <i class="fa-solid fa-money-check-dollar text-danger mr-1"></i>
                <h5 class="mb-0 mr-3 text-danger">Tiền mặt</h5>
              </div>
            </template>
            <b-card-text>
              <div id="chart">
                <DonutChart
                  :data="series"
                  :labels="[
                    'Tổng tiền mặt chưa nộp',
                    'Tổng tiền mặt đã nộp',
                    'Tổng tiền mặt đã xác nhận',
                  ]"
                />
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="7"
          ><div>
            <div class="card card-custom card-transparent">
              <div class="card-body p-0">
                <div
                  class="wizard wizard-4"
                  id="kt_wizard_v4"
                  data-wizard-state="step-first"
                  data-wizard-clickable="true"
                >
                  <div class="wizard-nav">
                    <div class="wizard-steps">
                      <div
                        class="wizard-step"
                        data-wizard-type="step"
                        v-for="tab in tabs"
                        :key="tab.id"
                      >
                        <div class="wizard-wrapper">
                          <div class="wizard-number">{{ tab.step }}</div>
                          <div class="wizard-label">
                            <div class="wizard-title">{{ tab.name }}</div>
                            <div class="wizard-desc">Doanh thu</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-custom card-shadowless rounded-top-0">
                    <div class="card-body">
                      <div class="mt-3">
                        <TableStatusNoteBook
                          :tblDatas="ftBillByStatus"
                          :tab="currentTab"
                          v-on:selected-bill="onSelectedBills"
                        />
                      </div>
                      <b-row class="mt-10">
                        <b-col cols="6">
                          <b-button
                            variant="secondary"
                            @click="onSentRevenue"
                            v-if="currentTab === 'sent-revenue'"
                            :disabled="!billSelected.length"
                            class="font-weight-bolder form-control"
                          >
                            Xác nhận doanh thu
                            <b-badge variant="light" class="ml-1"
                              >{{ billSelected.length }}
                            </b-badge>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  <style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>;
  <script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice } from '@/utils/common';
import { swalMixin } from '@/view/mixins/';
import TableStatusNoteBook from './TableStatusNoteBook';
import moment from 'moment';
import { mapGetters } from 'vuex';
import KTWizard from '@/assets/js/components/wizard';
import DonutChart from '../../base/apex-charts/DonutChart.vue';
import { PAYMENT_TYPE, WARRANTY_BILL_STATUS } from '../../../utils/enum';

export default {
  mixins: [swalMixin],
  data() {
    return {
      mainDataLeft: {
        createdAt: {
          name: 'Ngày',
          value: moment().format('DD/MM/YYYY'),
          show: false,
        },
        employeeName: {
          name: 'Nhân viên',
          value: '',
          show: false,
        },
        storeName: {
          name: 'Cửa hàng',
          value: '',
          show: false,
        },
        totalAmountOneDate: {
          name: 'Tổng doanh thu',
          value: () => {
            return this.totalAmountOneDate;
          },
          show: false,
        },
        totalRow: {
          name: 'Tổng hoá đơn',
          value: 0,
          show: false,
        },
        noSentRevenue: {
          name: 'Tổng chưa thu',
          value: () => {
            return this.noSentRevenue;
          },
          show: false,
        },
        sentRevenue: {
          name: 'Tổng đã nộp',
          value: () => {
            return this.sentRevenue;
          },
          show: false,
        },
        verifyRevenue: {
          name: 'Tổng đã xác nhận',
          value: () => {
            return this.verifyRevenue;
          },
          show: false,
        },
        totalCash: {
          name: 'Tổng tiền mặt',
          value: () => {
            return convertPrice(this.totalCash);
          },
          show: false,
        },
        totalTransfer: {
          name: 'Tổng tiền chuyển khoản',
          value: () => {
            return convertPrice(this.totalTransfer);
          },
          show: false,
        },
        totalCredit: {
          name: 'Tổng tiền quẹt thẻ',
          value: () => {
            return convertPrice(this.totalCredit);
          },
          show: false,
        },
        totalInstallment: {
          name: 'Tổng tiền trả góp',
          value: () => {
            return convertPrice(this.totalInstallment);
          },
          show: false,
        },
        bills: [],
        storeId: '',
      },
      tabs: [
        {
          step: 1,
          id: 'no-sent-revenue',
          name: 'Chưa nộp',
          status: [null, 1],
        },
        {
          step: 2,
          id: 'sent-revenue',
          name: 'Đã nộp',
          status: [WARRANTY_BILL_STATUS.SENT_REVENUE],
        },
        {
          step: 3,
          id: 'verify-revenue',
          name: 'Xác nhận',
          status: [11],
        },
      ],
      currentTab: 'no-sent-revenue',
      billSelected: [],
    };
  },
  components: {
    TableStatusNoteBook,
    DonutChart,
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    ftBillByStatus() {
      return this.mainDataLeft.bills.filter((bill) => {
        return this.tabs.some(
          (tab) =>
            tab.id === this.currentTab && tab.status.includes(bill.status),
        );
      });
    },
    series() {
      return [this.totalCashNotSent, this.totalCashSent, this.totalCashVerify];
    },
    totalCash() {
      return this.sumTotalAmountPayment(PAYMENT_TYPE.CASH);
    },
    totalTransfer() {
      return this.sumTotalAmountPayment(PAYMENT_TYPE.TRANSFER);
    },
    totalCredit() {
      return this.sumTotalAmountPayment(PAYMENT_TYPE.CREDIT);
    },
    totalInstallment() {
      return this.sumTotalAmountPayment(PAYMENT_TYPE.INSTALLMENT);
    },
    sentRevenue() {
      return this.sumTotalByBillStatus(WARRANTY_BILL_STATUS.SENT_REVENUE);
    },
    verifyRevenue() {
      return this.sumTotalByBillStatus(WARRANTY_BILL_STATUS.VERIFY_REVENUE);
    },
    noSentRevenue() {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        if ([null, WARRANTY_BILL_STATUS.NEW].includes(currentItem.status)) {
          result += currentItem.totalAmount;
        }
        return result;
      }, 0);
      return convertPrice(amount);
    },
    totalCashNotSent() {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        if ([null, WARRANTY_BILL_STATUS.NEW].includes(currentItem.status)) {
          const cashAmount = currentItem.payments.reduce(
            (paymentTotal, currentPayment) => {
              if (currentPayment.paymentType === PAYMENT_TYPE.CASH) {
                paymentTotal += currentPayment.paymentAmount;
              }
              return paymentTotal;
            },
            0,
          );
          result += cashAmount;
        }
        return result;
      }, 0);
      return amount;
    },
    totalCashSent() {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        if ([WARRANTY_BILL_STATUS.SENT_REVENUE].includes(currentItem.status)) {
          const cashAmount = currentItem.payments.reduce(
            (paymentTotal, currentPayment) => {
              if (currentPayment.paymentType === PAYMENT_TYPE.CASH) {
                paymentTotal += currentPayment.paymentAmount;
              }
              return paymentTotal;
            },
            0,
          );
          result += cashAmount;
        }
        return result;
      }, 0);
      return amount;
    },
    totalCashVerify() {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        if (
          [WARRANTY_BILL_STATUS.VERIFY_REVENUE].includes(currentItem.status)
        ) {
          const cashAmount = currentItem.payments.reduce(
            (paymentTotal, currentPayment) => {
              if (currentPayment.paymentType === PAYMENT_TYPE.CASH) {
                paymentTotal += currentPayment.paymentAmount;
              }
              return paymentTotal;
            },
            0,
          );
          result += cashAmount;
        }
        return result;
      }, 0);
      return amount;
    },
    totalAmountOneDate() {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        return result + currentItem.totalAmount;
      }, 0);
      return convertPrice(amount);
    },
    currentTabIndex: {
      get() {
        const index = this.tabs.findIndex(
          (item) => item.id === this.currentTab,
        );
        return index;
      },
      set() {
        const index = this.tabs.findIndex(
          (item) => item.id === this.currentTab,
        );
        return index;
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Sổ tay tiền mặt' }]);
    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Change event
    this.$nextTick(function () {
      wizard.on('change', this.onChangeTab);
    });
  },
  async created() {
    this.onInitData();
    await this.fetchStoreByUser();
    this.fetchData();
  },
  methods: {
    convertPrice,
    onInitData() {},
    onChangeTab(tab) {
      const tabSelected = this.tabs.find(
        (item) => item.step === tab.currentStep,
      );
      this.currentTab = tabSelected.id;
    },
    cvtBillSelect(bills) {
      return Object.values(bills).flat();
    },
    sumTotalByBillStatus(status) {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        if (currentItem.status === status) {
          result += currentItem.totalAmount;
        }
        return result;
      }, 0);
      return convertPrice(amount);
    },
    sumTotalAmountPayment(type) {
      const amount = this.mainDataLeft.bills.reduce((result, currentItem) => {
        const cashAmount = currentItem.payments.reduce(
          (paymentTotal, currentPayment) => {
            if (currentPayment.paymentType === type) {
              paymentTotal += currentPayment.paymentAmount;
            }
            return paymentTotal;
          },
          0,
        );
        result += cashAmount;
        return result;
      }, 0);
      return amount;
    },
    fetchStoreByUser: async function () {
      return ApiService.get(`stores/get-by-user`).then((response) => {
        const { data } = response.data;
        this.mainDataLeft.employeeName.value = data.employeeName;
        this.mainDataLeft.storeId = data.storeId;
        this.mainDataLeft.storeName.value = data.storeId
          ? data.stores.find((store) => store.id === data.storeId).name
          : 'Chưa gán';
      });
    },
    fetchData() {
      const currentDate = this.mainDataLeft.createdAt.value;
      const storeId = this.mainDataLeft.storeId;
      const fromDate = moment(currentDate, 'DD/MM/YYYY').format('yyyy-MM-DD');
      const toDate =
        moment(currentDate, 'DD/MM/YYYY').format('yyyy-MM-DD') + ' 23:59:59';
      ApiService.query('bills', {
        params: {
          page: 1,
          limit: 10,
          fromDate: fromDate,
          toDate: toDate,
          store: [storeId],
          companyId: 3,
          type: 1,
        },
      }).then((response) => {
        const { data } = response.data;
        this.mainDataLeft.totalRow.value = data.totalRow;
        this.mainDataLeft.bills = data.dataset;
      });
    },
    async onSentRevenue() {
      if (!this.billSelected.length) {
        this.popupSwalError({
          title: `Lỗi chọn hoá đơn`,
          text: `Không có hoá đơn nào được chọn`,
        });
        return;
      }
      const choice = await this.popupSwalWarning({
        title: `Chuyển doanh thu`,
        text: `Xác nhận doanh thu hoá ${this.billSelected.length} đơn`,
      });
      switch (choice.isConfirmed) {
        case true: {
          ApiService.post('bills/status', {
            bills: this.billSelected,
            mode: 'verify-revenue',
          }).then(() => {
            this.billSelected = [];
            this.fetchData();
          });
          break;
        }
      }
    },
    onSelectedBills(item) {
      console.log('item', item);
      if (item.selected) {
        const isExist = this.billSelected.find((select) => select === item);
        if (!isExist) this.billSelected.push(item.billNumber);
      } else {
        this.billSelected = this.billSelected.filter(
          (select) => select !== item.billNumber,
        );
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.note-books ::v-deep {
  // .card-header {
  //   padding: 1rem 1.25rem;
  // }
  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(30% - 0.15rem);
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(30% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    // background-color: #eef0f8;
    // border-radius: 4px 4px 0px 0px;
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }
}
</style>
  