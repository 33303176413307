<template>
  <div>
    <apexchart
      type="pie"
      :options="chartOptions"
      :series="series"
      width="100%"
    ></apexchart>
  </div>
</template>

<script>
import { convertPrice } from '@/utils/common';
import { mapGetters } from 'vuex';

export default {
  props: {
    data: {
      type: Array,
    },
    labels: {
      type: Array,
    },
    type: {
      type: String,
      default: 'donut',
    },
  },
  data() {
    return {
      chartOptions: {},
    };
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    series() {
      return this.data.filter(Number);
    },
  },
  mounted() {
    this.chartOptions = {
      chart: {
        type: this.type,
      },
      noData: {
        text: 'Không có dữ liệu ngày hôm nay',
        align: 'center',
        verticalAlign: 'middle',
      },
      labels: this.labels,
      responsive: [
        {
          breakpoint: 200,
          options: {
            chart: {
              width: 50,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return convertPrice(value) + ' VNĐ';
          },
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                offsetY: -20,
              },
              value: {
                show: true,
                fontSize: '12px',
                offsetY: -5,
              },
              total: {
                show: true,
                label: 'Tổng',
                fontWeight: 500,
                color: '#373d3f',
                formatter: function (w) {
                  const totalAmount = w.globals.seriesTotals.reduce(
                    (numberA, numberB) => {
                      const sum = (numberA || 0) + (numberB || 0);
                      return sum;
                    },
                    0,
                  );
                  return convertPrice(totalAmount) + ' VNĐ';
                },
              },
            },
          },
        },
      },
      // dataLabels: {
      //   enabled: true,
      //   enabledOnSeries: undefined,
      //   formatter: function (val) {
      //     console.log('val', val);
      //     return val;
      //   },
      //   textAnchor: 'middle',
      //   distributed: false,
      //   offsetX: 0,
      //   offsetY: 0,
      //   style: {
      //     fontSize: '14px',
      //     fontFamily: 'Helvetica, Arial, sans-serif',
      //     fontWeight: 'bold',
      //     colors: undefined,
      //   },
      //   background: {
      //     enabled: true,
      //     foreColor: '#fff',
      //     padding: 4,
      //     borderRadius: 2,
      //     borderWidth: 1,
      //     borderColor: '#fff',
      //     opacity: 0.9,
      //     dropShadow: {
      //       enabled: false,
      //       top: 1,
      //       left: 1,
      //       blur: 1,
      //       color: '#000',
      //       opacity: 0.45,
      //     },
      //   },
      //   dropShadow: {
      //     enabled: false,
      //     top: 1,
      //     left: 1,
      //     blur: 1,
      //     color: '#000',
      //     opacity: 0.45,
      //   },
      // },
    };
  },
};
</script>
